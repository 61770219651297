/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size  : inherit;
  font-weight: inherit
}

a {
  color          : inherit;
  text-decoration: inherit
}

b,
strong {
  font-weight: bolder
}

code,
kbd,
pre,
samp {
  font-family: Consolas, ui-monospace, SFMono-Regular, Menlo, Monaco, Liberation Mono, Courier New, monospace;
  font-size  : 1em
}

small {
  font-size: 80%
}

sub,
sup {
  font-size     : 75%;
  line-height   : 0;
  position      : relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

table {
  text-indent    : 0;
  border-color   : inherit;
  border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size  : 100%;
  font-weight: inherit;
  line-height: inherit;
  color      : inherit;
  margin     : 0;
  padding    : 0
}

button,
select {
  text-transform: none
}

/* [type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
  background-color  : transparent;
  background-image  : none
} */

:-moz-focusring {
  outline: auto
}

:-moz-ui-invalid {
  box-shadow: none
}

progress {
  vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset    : -2px
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font              : inherit
}

summary {
  display: list-item
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0
}

fieldset {
  margin: 0
}

fieldset,
legend {
  padding: 0
}

menu,
ol,
ul {
  list-style: none;
  margin    : 0;
  padding   : 0
}

textarea {
  resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #9ca3af
}

input::placeholder,
textarea::placeholder {
  color: #9ca3af
}

[role=button],
button {
  cursor: pointer
}

:disabled {
  cursor: default
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display       : block;
  vertical-align: middle
}

img,
video {
  max-width: 100%;
  height   : auto
}

[hidden] {
  display: none
}

[multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance   : none;
  appearance        : none;
  background-color  : #fff;
  border-color      : #6b7280;
  border-width      : 1px;
  border-radius     : 0;
  padding           : .5rem .75rem;
  font-size         : 1rem;
  line-height       : 1.5rem;
  --tw-shadow       : 0 0 #0000
}

[multiple]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=email]:focus,
[type=month]:focus,
[type=number]:focus,
[type=password]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=text]:focus,
[type=time]:focus,
[type=url]:focus,
[type=week]:focus,
select:focus,
textarea:focus {
  outline       : 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width : 0px;
  --tw-ring-offset-color : #fff;
  --tw-ring-color        : #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow       : var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow             : var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color           : #2563eb
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color  : #6b7280;
  opacity: 1
}

input::placeholder,
textarea::placeholder {
  color  : #6b7280;
  opacity: 1
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0
}

::-webkit-date-and-time-value {
  min-height: 1.5em
}

::-webkit-datetime-edit,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-meridiem-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-year-field {
  padding-top   : 0;
  padding-bottom: 0
}

select {
  background-image          : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position       : right .5rem center;
  background-repeat         : no-repeat;
  background-size           : 1.5em 1.5em;
  padding-right             : 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust        : exact
}

[multiple] {
  background-image          : none;
  background-position       : 0 0;
  background-repeat         : unset;
  background-size           : initial;
  padding-right             : .75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust        : unset
}

[type=checkbox],
[type=radio] {
  -webkit-appearance        : none;
  -moz-appearance           : none;
  appearance                : none;
  padding                   : 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust        : exact;
  display                   : inline-block;
  vertical-align            : middle;
  background-origin         : border-box;
  -webkit-user-select       : none;
  -moz-user-select          : none;
  user-select               : none;
  flex-shrink               : 0;
  height                    : 1rem;
  width                     : 1rem;
  color                     : #2563eb;
  background-color          : #fff;
  border-color              : #6b7280;
  border-width              : 1px;
  --tw-shadow               : 0 0 #0000
}

[type=checkbox] {
  border-radius: 0
}

[type=radio] {
  border-radius: 100%
}

[type=checkbox]:focus,
[type=radio]:focus {
  outline       : 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width : 2px;
  --tw-ring-offset-color : #fff;
  --tw-ring-color        : #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow       : var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow             : var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)
}

[type=checkbox]:checked,
[type=radio]:checked {
  border-color       : transparent;
  background-color   : currentColor;
  background-size    : 100% 100%;
  background-position: 50%;
  background-repeat  : no-repeat
}

[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
}

[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")
}

[type=checkbox]:checked:focus,
[type=checkbox]:checked:hover,
[type=radio]:checked:focus,
[type=radio]:checked:hover {
  border-color    : transparent;
  background-color: currentColor
}

[type=checkbox]:indeterminate {
  background-image   : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color       : transparent;
  background-color   : currentColor;
  background-size    : 100% 100%;
  background-position: 50%;
  background-repeat  : no-repeat
}

[type=checkbox]:indeterminate:focus,
[type=checkbox]:indeterminate:hover {
  border-color    : transparent;
  background-color: currentColor
}

[type=file] {
  background   : unset;
  border-color : inherit;
  border-width : 0;
  border-radius: 0;
  padding      : 0;
  font-size    : unset;
  line-height  : inherit
}

[type=file]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color
}

*,
:after,
:before {
  --tw-border-spacing-x      : 0;
  --tw-border-spacing-y      : 0;
  --tw-translate-x           : 0;
  --tw-translate-y           : 0;
  --tw-rotate                : 0;
  --tw-skew-x                : 0;
  --tw-skew-y                : 0;
  --tw-scale-x               : 1;
  --tw-scale-y               : 1;
  --tw-pan-x                 : ;
  --tw-pan-y                 : ;
  --tw-pinch-zoom            : ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position : ;
  --tw-gradient-to-position  : ;
  --tw-ordinal               : ;
  --tw-slashed-zero          : ;
  --tw-numeric-figure        : ;
  --tw-numeric-spacing       : ;
  --tw-numeric-fraction      : ;
  --tw-ring-inset            : ;
  --tw-ring-offset-width     : 0px;
  --tw-ring-offset-color     : #fff;
  --tw-ring-color            : rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow    : 0 0 #0000;
  --tw-ring-shadow           : 0 0 #0000;
  --tw-shadow                : 0 0 #0000;
  --tw-shadow-colored        : 0 0 #0000;
  --tw-blur                  : ;
  --tw-brightness            : ;
  --tw-contrast              : ;
  --tw-grayscale             : ;
  --tw-hue-rotate            : ;
  --tw-invert                : ;
  --tw-saturate              : ;
  --tw-sepia                 : ;
  --tw-drop-shadow           : ;
  --tw-backdrop-blur         : ;
  --tw-backdrop-brightness   : ;
  --tw-backdrop-contrast     : ;
  --tw-backdrop-grayscale    : ;
  --tw-backdrop-hue-rotate   : ;
  --tw-backdrop-invert       : ;
  --tw-backdrop-opacity      : ;
  --tw-backdrop-saturate     : ;
  --tw-backdrop-sepia        :
}

::backdrop {
  --tw-border-spacing-x      : 0;
  --tw-border-spacing-y      : 0;
  --tw-translate-x           : 0;
  --tw-translate-y           : 0;
  --tw-rotate                : 0;
  --tw-skew-x                : 0;
  --tw-skew-y                : 0;
  --tw-scale-x               : 1;
  --tw-scale-y               : 1;
  --tw-pan-x                 : ;
  --tw-pan-y                 : ;
  --tw-pinch-zoom            : ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position : ;
  --tw-gradient-to-position  : ;
  --tw-ordinal               : ;
  --tw-slashed-zero          : ;
  --tw-numeric-figure        : ;
  --tw-numeric-spacing       : ;
  --tw-numeric-fraction      : ;
  --tw-ring-inset            : ;
  --tw-ring-offset-width     : 0px;
  --tw-ring-offset-color     : #fff;
  --tw-ring-color            : rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow    : 0 0 #0000;
  --tw-ring-shadow           : 0 0 #0000;
  --tw-shadow                : 0 0 #0000;
  --tw-shadow-colored        : 0 0 #0000;
  --tw-blur                  : ;
  --tw-brightness            : ;
  --tw-contrast              : ;
  --tw-grayscale             : ;
  --tw-hue-rotate            : ;
  --tw-invert                : ;
  --tw-saturate              : ;
  --tw-sepia                 : ;
  --tw-drop-shadow           : ;
  --tw-backdrop-blur         : ;
  --tw-backdrop-brightness   : ;
  --tw-backdrop-contrast     : ;
  --tw-backdrop-grayscale    : ;
  --tw-backdrop-hue-rotate   : ;
  --tw-backdrop-invert       : ;
  --tw-backdrop-opacity      : ;
  --tw-backdrop-saturate     : ;
  --tw-backdrop-sepia        :
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.prose {
  color    : var(--tw-prose-body);
  max-width: 65ch
}

.prose :where(p):not(:where([class~=not-prose] *)) {
  margin-top   : 1.25em;
  margin-bottom: 1.25em
}

.prose :where([class~=lead]):not(:where([class~=not-prose] *)) {
  color        : var(--tw-prose-lead);
  font-size    : 1.25em;
  line-height  : 1.6;
  margin-top   : 1.2em;
  margin-bottom: 1.2em
}

.prose :where(a):not(:where([class~=not-prose] *)) {
  color          : var(--tw-prose-links);
  text-decoration: underline;
  font-weight    : 500
}

.prose :where(strong):not(:where([class~=not-prose] *)) {
  color      : var(--tw-prose-bold);
  font-weight: 600
}

.prose :where(a strong):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(blockquote strong):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(thead th strong):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(ol):not(:where([class~=not-prose] *)) {
  list-style-type: decimal;
  margin-top     : 1.25em;
  margin-bottom  : 1.25em;
  padding-left   : 1.625em
}

.prose :where(ol[type=A]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-alpha
}

.prose :where(ol[type=a]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-alpha
}

.prose :where(ol[type=A s]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-alpha
}

.prose :where(ol[type=a s]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-alpha
}

.prose :where(ol[type=I]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-roman
}

.prose :where(ol[type=i]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-roman
}

.prose :where(ol[type=I s]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-roman
}

.prose :where(ol[type=i s]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-roman
}

.prose :where(ol[type="1"]):not(:where([class~=not-prose] *)) {
  list-style-type: decimal
}

.prose :where(ul):not(:where([class~=not-prose] *)) {
  list-style-type: disc;
  margin-top     : 1.25em;
  margin-bottom  : 1.25em;
  padding-left   : 1.625em
}

.prose :where(ol>li):not(:where([class~=not-prose] *))::marker {
  font-weight: 400;
  color      : var(--tw-prose-counters)
}

.prose :where(ul>li):not(:where([class~=not-prose] *))::marker {
  color: var(--tw-prose-bullets)
}

.prose :where(hr):not(:where([class~=not-prose] *)) {
  border-color    : var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top      : 3em;
  margin-bottom   : 3em
}

.prose :where(blockquote):not(:where([class~=not-prose] *)) {
  font-weight      : 500;
  font-style       : italic;
  color            : var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes           : "\201C""\201D""\2018""\2019";
  margin-top       : 1.6em;
  margin-bottom    : 1.6em;
  padding-left     : 1em
}

.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose] *)):before {
  content: none
}

.prose :where(blockquote p:last-of-type):not(:where([class~=not-prose] *)):after {
  content: close-quote
}

.prose :where(h1):not(:where([class~=not-prose] *)) {
  color        : var(--tw-prose-headings);
  font-weight  : 800;
  font-size    : 2.25em;
  margin-top   : 0;
  margin-bottom: .8888889em;
  line-height  : 1.1111111;
  font-family  : default
}

.prose :where(h1 strong):not(:where([class~=not-prose] *)) {
  font-weight: 900;
  color      : inherit
}

.prose :where(h2):not(:where([class~=not-prose] *)) {
  color        : var(--tw-prose-headings);
  font-weight  : 700;
  font-size    : 1.5em;
  margin-top   : 2em;
  margin-bottom: 1em;
  line-height  : 1.3333333;
  font-family  : default
}

.prose :where(h2 strong):not(:where([class~=not-prose] *)) {
  font-weight: 800;
  color      : inherit
}

.prose :where(h3):not(:where([class~=not-prose] *)) {
  color        : var(--tw-prose-headings);
  font-weight  : 600;
  font-size    : 1.25em;
  margin-top   : 1.6em;
  margin-bottom: .6em;
  line-height  : 1.6;
  font-family  : default
}

.prose :where(h3 strong):not(:where([class~=not-prose] *)) {
  font-weight: 700;
  color      : inherit
}

.prose :where(h4):not(:where([class~=not-prose] *)) {
  color        : var(--tw-prose-headings);
  font-weight  : 600;
  margin-top   : 1.5em;
  margin-bottom: .5em;
  line-height  : 1.5
}

.prose :where(h4 strong):not(:where([class~=not-prose] *)) {
  font-weight: 700;
  color      : inherit
}

.prose :where(img):not(:where([class~=not-prose] *)) {
  margin-top   : 2em;
  margin-bottom: 2em
}

.prose :where(figure>*):not(:where([class~=not-prose] *)) {
  margin-top   : 0;
  margin-bottom: 0
}

.prose :where(figcaption):not(:where([class~=not-prose] *)) {
  color      : var(--tw-prose-captions);
  font-size  : .875em;
  line-height: 1.4285714;
  margin-top : .8571429em
}

.prose :where(code):not(:where([class~=not-prose] *)) {
  color      : var(--tw-prose-code);
  font-weight: 600;
  font-size  : .875em
}

.prose :where(code):not(:where([class~=not-prose] *)):before {
  content: "`"
}

.prose :where(code):not(:where([class~=not-prose] *)):after {
  content: "`"
}

.prose :where(a code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(h1 code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(h2 code):not(:where([class~=not-prose] *)) {
  color    : inherit;
  font-size: .875em
}

.prose :where(h3 code):not(:where([class~=not-prose] *)) {
  color    : inherit;
  font-size: .9em
}

.prose :where(h4 code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(blockquote code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(thead th code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(pre):not(:where([class~=not-prose] *)) {
  color           : var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x      : auto;
  font-weight     : 400;
  font-size       : .875em;
  line-height     : 1.7142857;
  margin-top      : 1.7142857em;
  margin-bottom   : 1.7142857em;
  border-radius   : .375rem;
  padding         : .8571429em 1.1428571em
}

.prose :where(pre code):not(:where([class~=not-prose] *)) {
  background-color: transparent;
  border-width    : 0;
  border-radius   : 0;
  padding         : 0;
  font-weight     : inherit;
  color           : inherit;
  font-size       : inherit;
  font-family     : inherit;
  line-height     : inherit
}

.prose :where(pre code):not(:where([class~=not-prose] *)):before {
  content: none
}

.prose :where(pre code):not(:where([class~=not-prose] *)):after {
  content: none
}

.prose :where(table):not(:where([class~=not-prose] *)) {
  width        : 100%;
  table-layout : auto;
  text-align   : left;
  margin-top   : 2em;
  margin-bottom: 2em;
  font-size    : .875em;
  line-height  : 1.7142857
}

.prose :where(thead):not(:where([class~=not-prose] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders)
}

.prose :where(thead th):not(:where([class~=not-prose] *)) {
  color         : var(--tw-prose-headings);
  font-weight   : 600;
  vertical-align: bottom;
  padding-right : .5714286em;
  padding-bottom: .5714286em;
  padding-left  : .5714286em
}

.prose :where(tbody tr):not(:where([class~=not-prose] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders)
}

.prose :where(tbody tr:last-child):not(:where([class~=not-prose] *)) {
  border-bottom-width: 0
}

.prose :where(tbody td):not(:where([class~=not-prose] *)) {
  vertical-align: baseline
}

.prose :where(tfoot):not(:where([class~=not-prose] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders)
}

.prose :where(tfoot td):not(:where([class~=not-prose] *)) {
  vertical-align: top
}

.prose {
  --tw-prose-body                : #374151;
  --tw-prose-headings            : #111827;
  --tw-prose-lead                : #4b5563;
  --tw-prose-links               : #111827;
  --tw-prose-bold                : #111827;
  --tw-prose-counters            : #6b7280;
  --tw-prose-bullets             : #d1d5db;
  --tw-prose-hr                  : #e5e7eb;
  --tw-prose-quotes              : #111827;
  --tw-prose-quote-borders       : #e5e7eb;
  --tw-prose-captions            : #6b7280;
  --tw-prose-code                : #111827;
  --tw-prose-pre-code            : #e5e7eb;
  --tw-prose-pre-bg              : #1f2937;
  --tw-prose-th-borders          : #d1d5db;
  --tw-prose-td-borders          : #e5e7eb;
  --tw-prose-invert-body         : #d1d5db;
  --tw-prose-invert-headings     : #fff;
  --tw-prose-invert-lead         : #9ca3af;
  --tw-prose-invert-links        : #fff;
  --tw-prose-invert-bold         : #fff;
  --tw-prose-invert-counters     : #9ca3af;
  --tw-prose-invert-bullets      : #4b5563;
  --tw-prose-invert-hr           : #374151;
  --tw-prose-invert-quotes       : #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions     : #9ca3af;
  --tw-prose-invert-code         : #fff;
  --tw-prose-invert-pre-code     : #d1d5db;
  --tw-prose-invert-pre-bg       : rgba(0, 0, 0, .5);
  --tw-prose-invert-th-borders   : #4b5563;
  --tw-prose-invert-td-borders   : #374151;
  font-size                      : 1rem;
  line-height                    : 1.75
}

.prose :where(video):not(:where([class~=not-prose] *)) {
  margin-top   : 2em;
  margin-bottom: 2em
}

.prose :where(figure):not(:where([class~=not-prose] *)) {
  margin-top   : 2em;
  margin-bottom: 2em
}

.prose :where(li):not(:where([class~=not-prose] *)) {
  margin-top   : .5em;
  margin-bottom: .5em
}

.prose :where(ol>li):not(:where([class~=not-prose] *)) {
  padding-left: .375em
}

.prose :where(ul>li):not(:where([class~=not-prose] *)) {
  padding-left: .375em
}

.prose :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-top   : .75em;
  margin-bottom: .75em
}

.prose :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.25em
}

.prose :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.25em
}

.prose :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.25em
}

.prose :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.25em
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~=not-prose] *)) {
  margin-top   : .75em;
  margin-bottom: .75em
}

.prose :where(hr+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(h2+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(h3+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(h4+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(thead th:first-child):not(:where([class~=not-prose] *)) {
  padding-left: 0
}

.prose :where(thead th:last-child):not(:where([class~=not-prose] *)) {
  padding-right: 0
}

.prose :where(tbody td, tfoot td):not(:where([class~=not-prose] *)) {
  padding: .5714286em
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~=not-prose] *)) {
  padding-left: 0
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~=not-prose] *)) {
  padding-right: 0
}

.prose :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 0
}

.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose] *)):after {
  content: none
}

span.marker {
  font-weight: 500;
}